import type { FeatureFlag } from '@forgd/shared/feature-flags'

export async function useFeatureFlag(featureFlag: FeatureFlag) {
  return new Promise<boolean>((resolve) => {
    usePostHog((posthog) => {
      posthog.onFeatureFlags(() => {
        resolve(posthog.isFeatureEnabled(featureFlag) ?? false)
      })
    })
  }).catch(() => false)
}
