import type { Ref } from 'vue'
import { isPlainObject } from '#core/utils/assert'
import { twMerge } from 'tailwind-merge'
import { useFormLayout } from './layout'

// ---------------------------------------------------------------------------------------------------------------------
// constants
// ---------------------------------------------------------------------------------------------------------------------

export const HEIGHTS = {
  // heights defined by Lucas
  xs: 'h-[24px]', // h-6
  sm: 'h-[32px]', // h-8
  md: 'h-[40px]', // h-10
  lg: 'h-[52px]', // h-13 (doesn't exist)
  xl: 'h-[60px]', // h-15 (doesn't exist)
}

export type InputSize = keyof typeof HEIGHTS

export const SIZES = Object.keys(HEIGHTS)

// ---------------------------------------------------------------------------------------------------------------------
// classes
// ---------------------------------------------------------------------------------------------------------------------

// type InputType = 'input' | 'select' | 'switch'

// export type InputValue = string | number | null | undefined

interface CommonProps {
  [key: string]: any
  modelValue?: any
  size: InputSize
  status?: 'success' | 'error'
  error?: boolean | string
  disabled?: boolean
}

interface InputProps {
  type?: string
  readonly?: boolean
}

interface SelectProps {
  options?: OptionsProp
}

type Props = CommonProps & InputProps & SelectProps

/**
 * Generate common classes across all inputs
 */
export function useInputClasses(props: Props, model: Ref) {
  const layout = useFormLayout({
    inputSize: props.size,
  })

  const hasValue = computed(() => {
    return model.value !== '' && model.value !== null && model.value !== undefined
  })

  const isDirty = computed(() => model.value !== props.modelValue)

  const isEmpty = computed(() => {
    return model.value === undefined || (typeof model.value === 'string' && model.value.trim() === '')
  })

  const color = computed(() => {
    return props.disabled
      ? 'text-neutral-900/30'
      : props.readonly
        ? 'text-forgd-gray-600'
        : hasValue.value || props.options
          ? 'text-brand-900'
          : 'text-neutral-900/60'
  })

  const text = computed(() => {
    const sizes = {
      xs: 'text-xs',
      sm: 'text-xs',
      md: 'text-base',
      lg: 'text-base',
      xl: 'text-lg',
    }
    return `${sizes[layout.inputSize.value]} leading-[100%]`
  })

  const size = computed(() => {
    const height = HEIGHTS[layout.inputSize.value] || HEIGHTS.md
    const padding = {
      xs: 'px-2',
      sm: 'px-2',
      md: 'px-2',
      lg: 'px-3',
      xl: 'px-4',
    }
    return `${padding[layout.inputSize.value]} ${height}`
  })

  const error = computed(() => props.error ? '!ring-red-600' : null)

  const disabled = computed(() => {
    const color = props.disabled || props.readonly
      ? 'bg-neutral-600/30 ring-neutral-600/60'
      : ''
    const cursor = props.disabled
      ? 'cursor-not-allowed'
      : props.options
        ? 'cursor-default'
        : 'cursor-text'
    return `${color} ${cursor}`
  })

  const interaction = computed(() => {
    const hover = !(props.disabled || props.readonly)
      ? '[&:not(:focus-within):hover]:bg-neutral-600/10' // [&:not(:focus-within):hover_input]:text-brand-100
      : ''
    const focus = !props.status
      ? 'focus-within:ring-primary-500' // changed from accent-100 to match the rest of the app
      : ''
    return `${hover} ${focus}`
  })

  const element = computed(() => {
    return twMerge([
      `inline-flex items-center
      w-full rounded-lg
      text-forgd-primary-900 font-display
      border-0 ring-1 ring-inset
      ring-forgd-bgd-600
      focus-within:ring-inset focus-within:ring-2`,
      props.disabled ? '[&_*]:pointer-events-none' : '',
      disabled.value,
      error.value,
      interaction.value,
      color.value,
      text.value,
      size.value,
    ])
  })

  const input = computed(() => {
    return twMerge([
      text.value,
      color.value,
      props.type === 'password' && '[&::-ms-reveal]:hidden [&::-ms-clear]:hidden [appearance:none] [&::-webkit-credentials-auto-fill-button]:hidden',
      `grow w-full min-w-0
      border-0 ring-0 outline-0
      focus:border-0 focus:ring-0 focus:outline-0
      !p-0 !bg-transparent`,
    ])
  })

  const slot = computed(() => {
    return twMerge([
      text.value,
      color.value,
    ])
  })

  return reactive({
    // states
    hasValue,
    isEmpty,
    isDirty,

    // helper classes
    color,
    text,
    size,
    status,
    disabled,
    interaction,

    // component classes
    element,
    input,
    slot,
  })
}

// ---------------------------------------------------------------------------------------------------------------------
// options
// ---------------------------------------------------------------------------------------------------------------------

export type OptionsProp =
  | string[]
  | number[]
  | Record<string, any>[]
  | Record<string, any>

interface OptionAttributes {
  value?: string
  label?: string
}

export interface OptionModel extends OptionAttributes {
  [key: string]: any
}

/**
 * Find the first known key on an object
 *
 * @param obj
 * @param keys
 */
function findPossibleKey(obj: Record<string, any>, keys: string[]): string | undefined {
  for (const key of keys) {
    if (key in obj) {
      return key as string
    }
  }
}

// type Transformer = (value: string, index?: number) => any
//
// const optionTransforms: Record<string, Transformer> = {
//   default: value => value,
//   index: (_value: string, index?: number) => index,
//   label: (value: string) => value,
//   key: (value: string) => value,
// }

/**
 * Helper function to parse form component option props into a standard format
 *
 * Returns the parsed options and value / label attributes
 *
 * @param options   Options input
 // * @param tx
 */
export function parseOptions(options: OptionsProp): { options: OptionModel[], attributes: OptionAttributes } {
  // defaults
  const attributes: OptionAttributes = { value: 'value', label: 'label' }

  // plain object
  if (isPlainObject(options) && !Array.isArray(options)) {
    return {
      attributes,
      options: Object.entries(options).map(([label, value]) => ({
        label,
        value,
      })),
    }
  }

  // Array case
  if (Array.isArray(options)) {
    if (options.every(option => !isPlainObject(option))) {
      // string array
      return {
        attributes,
        options: options.map(label => ({
          label,
          value: label,
        })),
      }
    }

    // object array
    const option = options[0]
    return {
      attributes: {
        value: findPossibleKey(option, ['id', 'value']),
        label: findPossibleKey(option, ['label', 'title', 'name']),
      },
      options,
    }
  }

  // fallback
  return {
    attributes: {},
    options: [],
  }
}
