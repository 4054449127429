
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexUGlnNBCjC_45_45M8rEH7jSPl8KB5Ws6CVixn2vMxO8hygEMeta } from "/opt/render/project/src/apps/ui/layers/dashboard/pages/index.vue?macro=true";
import { default as gitbookGBmlgeMnoC28SgVDnT3UChgdoCTHq4U6MaAmbClNL5sMeta } from "/opt/render/project/src/apps/ui/pages/gitbook.vue?macro=true";
import { default as supportf6ME1XvBVM1LiwcnY0zB45guLOVTMgyyWdoyHUO6pKwMeta } from "/opt/render/project/src/apps/ui/pages/support.vue?macro=true";
import { default as settingsgapW9XLrxsw8hw_45iWdJz1x0TKvFeQc_z_45hi8W3fimM0Meta } from "/opt/render/project/src/apps/ui/layers/account-settings/pages/settings.vue?macro=true";
import { default as indexmvsUfjQL_45acudqc21na0tcybq_0hjDR_45hDyGl5qwAloMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/login/index.vue?macro=true";
import { default as mobile_45gatekAyyeZfEnhxcqb1T3i53y4mVJQQcdZLXvzjfj4cDuugMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/pages/mobile-gate.vue?macro=true";
import { default as index3ue27YG0B_45EdcYoaqPa6jEaGDxNhOkhrH9lM4h_45CqzcMeta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/register/index.vue?macro=true";
import { default as token_45settingsKimlgdMbFYkAIy913DIxQ5ZoUzJ8xdXhg9nnXGXP2NsMeta } from "/opt/render/project/src/apps/ui/layers/dashboard/pages/token-settings.vue?macro=true";
import { default as indexnIe0ite5Zhb_DxZ7rqbrNl7os0T_nPX_IxD4Mn_459DL4Meta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/magic-link/index.vue?macro=true";
import { default as indexs3VXQtOScb7lsbe21EMRW5s_9WK2fC_457KZLOSkUnMR0Meta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/onboarding/index.vue?macro=true";
import { default as indexIrgU1woHyWbPJQLFlS3OIa7WZrzWcVCt0SplteL7mvkMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/tokenomics/index.vue?macro=true";
import { default as authorizationMA9q0QGkUq89hDw_40oIFkIXVN9nH9W4uIGDWqndFUwMeta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/v2/authorization.vue?macro=true";
import { default as _91_46_46_46slug_93AqI5vzdMnbFJeIgoQHjUnn9AlfY5oNUjgbi6jXC6_d0Meta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/pages/academy/[...slug].vue?macro=true";
import { default as playbook_45advancedkJy3A_45FkhlvkfIkwz8h2mo_yVzktuQftpj4EeV3jMI8Meta } from "/opt/render/project/src/apps/ui/layers/dashboard/pages/playbook-advanced.vue?macro=true";
import { default as indexrsMIpkuc76ev75_cRP5eIV90A33EjtXVDo6iHL1l53IMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/password/new/index.vue?macro=true";
import { default as indexgQTfHNlJKHiUW_45SnfSkgGrdyM8TkcErG1Mdvki1lGL0Meta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/verify-email/index.vue?macro=true";
import { default as indexCecKR3D_454S2_gPaIm7BXluQxM77M5TH4GbNBdZ4vU4sMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/confirm-email/index.vue?macro=true";
import { default as pathZocmFISoG9X7JgwX5_ZX1jaAf7pjfQ6T5K79sTbEMJcMeta } from "/opt/render/project/src/apps/ui/layers/playground/pages/playground/api/path.vue?macro=true";
import { default as indexchwjBEzU3DYgaDf_4578n3F_MOoOPPonbm_A2SYV9E8dwMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-unlocks/index.vue?macro=true";
import { default as indexXzC_3k_45TTZ0bbWVcKTM8ihm3y1Dl2Qy4qwiQlVm8gxUMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/email-redirect/index.vue?macro=true";
import { default as indexVsJ3Y9CBWwhs_45cRYjB_45_SHjwMCnDMYytgncMMSJX2FAMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/page-not-found/index.vue?macro=true";
import { default as index9c6D2dkah0lTGE_45TZCBc_45oZGRe3tO_UL2EE40Aw9KOgMeta } from "/opt/render/project/src/apps/ui/layers/playground/pages/playground/api/index.vue?macro=true";
import { default as index3dyXAQxbkgylQA6DfyHAsw0Ek3Xn4ovpA2DLkuagWUUMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/reset-password/index.vue?macro=true";
import { default as index7h9mSdnWuZVzQKKviapqrKR4SMHljUvI3GmJmySEh5wMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/index.vue?macro=true";
import { default as _91code_93K4qjhvU1JkHGJi49gt8PICET2uXgQpV5xr_45E1ftLI6sMeta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/v2/invitation/[code].vue?macro=true";
import { default as indexWIGryPbaLoed4csmMnyPGFAVo0Mx4squsz1VsenpokYMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/forgot-password/index.vue?macro=true";
import { default as pathkdqzhAXVSx2mIOFNdhslFxYxAelrhOtVi3ebv_VOHdEMeta } from "/opt/render/project/src/apps/ui/pages/playground/pinia/path.vue?macro=true";
import { default as index_4579_45AgksIw_45QUfVcXRDsdmSNrco_45fFbYtqA5HhuM8I0Meta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/tokenomics/index.vue?macro=true";
import { default as indexXCZmgkw_zoPvGj8xXYGAIekzXTOwcBvk4Sxq_qLW2KMMeta } from "/opt/render/project/src/apps/ui/layers/exchange-listing/pages/exchange-listing/index.vue?macro=true";
import { default as indexsQESe2YUxjy6I6HSEGd8dBO38PE25zgcZJsBDxV4WqIMeta } from "/opt/render/project/src/apps/ui/pages/playground/pinia/index.vue?macro=true";
import { default as reactiveclVfUKJW4han5j8AxGtpd7IR1LuhusFG9vaTvWvhW6EMeta } from "/opt/render/project/src/apps/ui/layers/playground/pages/playground/api/reactive.vue?macro=true";
import { default as ui_45selectzhcRh60UxVtsEYCFeAgxwdbpAoZ35ilLuBJ9lQrIpQEMeta } from "/opt/render/project/src/apps/ui/layers/playground/pages/playground/api/ui-select.vue?macro=true";
import { default as indexgXioA2zbhxLLgQ4AZIftbh2MmU2pPtsEEGFzi4bCCsUMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-unlocks/index.vue?macro=true";
import { default as mutationoNORG0HJO9gpXlVn1xzOFvwBjFSs1Jx60E69Ew97T64Meta } from "/opt/render/project/src/apps/ui/pages/playground/pinia/mutation.vue?macro=true";
import { default as reactiveoYrPPUbKvF2g8ZgZx5t7paUE1V5Tmmb_sHm9x4pmdvwMeta } from "/opt/render/project/src/apps/ui/pages/playground/pinia/reactive.vue?macro=true";
import { default as index23_7ki6oKmkqmRtKn5DXIXW8ihYuuuF8oZuEKlqq7WkMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/index.vue?macro=true";
import { default as _91partner_93RV_454t1z4eqa4QGXJjjpaX3oPaU60pX6Xr_45dZON6QN0QMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-schedule/[partner].vue?macro=true";
import { default as ui_45selectuun6H21_dxI8_Qu1o5a0EtYIgVfLtZMCHJ3gOwgbc58Meta } from "/opt/render/project/src/apps/ui/pages/playground/pinia/ui-select.vue?macro=true";
import { default as indexMLKPgoo56OD1lAzgyp33Dy9mMKnzHFSP3ZchdMkAE8EMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/index.vue?macro=true";
import { default as listing_45feesKvCPVLJnqQ5gGB1kZAYKyDynQZl5wfpdiETuzjiFaJIMeta } from "/opt/render/project/src/apps/ui/layers/exchange-listing/pages/exchange-listing/listing-fees.vue?macro=true";
import { default as indexREQFlGg5ET5GjhFr5BWzAitbM49ffAha8_an_4525yKPAMeta } from "/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/index.vue?macro=true";
import { default as _91mode_93d3GDDH_45Znsf0Ict2mPqGpGTQ2VXqIu7_FW8rBuwpfDwMeta } from "/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/[mode].vue?macro=true";
import { default as indexT7i_45EDKoWt_6JLJs2N_51HwiGH0dIoidkUsQrU4NpMcMeta } from "/opt/render/project/src/apps/ui/layers/liquidity-launch-designer/pages/liquidity-launch-designer/index.vue?macro=true";
import { default as _91activity_93aY9u3pwTsAAKK1wAnh2P_45BW8_z6YIY3760pQHu2GDI0Meta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-activity-lead/[activity].vue?macro=true";
import { default as exchange_45ratings3qhKNZYFUKi9z99Hi25aQukC5gJhak66zucNEIGaLkgMeta } from "/opt/render/project/src/apps/ui/layers/exchange-listing/pages/exchange-listing/exchange-ratings.vue?macro=true";
import { default as dashboard1sXvtQ_Nj4zVMiDiTqa_45yQiN8bf4TNHB58fur7o48KQMeta } from "/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/dashboard.vue?macro=true";
import { default as publicneX7rM_453smVQpKPKPEUHzi2rTUbH5aoGxiAdf7QU_45CEMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/share/[ticker]/[projectId]/public.vue?macro=true";
import { default as indexj_45wMXnTlhesb1NrAqQ0cUxLs_45EN5P5TIwHoFNNVHv8EMeta } from "/opt/render/project/src/apps/ui/layers/market-maker-monitoring-next/pages/_next/market-maker-monitoring/index.vue?macro=true";
import { default as quote_45detailsEqhrO5T7bfgk2AuGOzbqE0HabeY0IVgNwmXHj_E3jZkMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/quote-details.vue?macro=true";
import { default as dashboardSUEFGG3wicyslSh_45kzWKLpnFn2nZmY8H2PnPzXh3gZ8Meta } from "/opt/render/project/src/apps/ui/layers/liquidity-launch-designer/pages/liquidity-launch-designer/dashboard.vue?macro=true";
import { default as teammate4wLPX43bK_45FefeAMhS4c5UzSxijLh7t9A9wuFBw2LpYMeta } from "/opt/render/project/src/apps/ui/layers/onboarding/pages/onboarding/[organizationId]/teammate.vue?macro=true";
import { default as received_45quotesJNdzbzxNVoks7c706SN1YqfOXmqAX5vD_A0rzDu2oRoMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-quotes.vue?macro=true";
import { default as confirmationdqqDTsk1KA4Vs5ENkdzDMJoSBOa_syO7_fEJ4r_ehc8Meta } from "/opt/render/project/src/apps/ui/layers/liquidity-launch-designer/pages/liquidity-launch-designer/confirmation.vue?macro=true";
import { default as demand_45driversYU_YmicxnAo04vQt5X_45OpqZk7K_45ah0U2yqb1iDaqZFUMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/demand-drivers.vue?macro=true";
import { default as dashboardjVIBLG79m9enzHZ7JoRBwI1Fni8QFYDjRb0HrjENZawMeta } from "/opt/render/project/src/apps/ui/layers/market-maker-monitoring-next/pages/_next/market-maker-monitoring/dashboard.vue?macro=true";
import { default as publickHH2V1hIitT02fElYueTURnFJoNDGxsEl_452pDcYx_45gMMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/share/[ticker]/[projectId]/public.vue?macro=true";
import { default as received_45requotes6k3O97vyUDdLsUv67_45eUwWXqoyLe2NMb9d0_74ZvPZAMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-requotes.vue?macro=true";
import { default as listing_45questionnairesPBHljWVUBLIplNTn6ol_45JuAm3c8hTYyUu694_tF1OR4Meta } from "/opt/render/project/src/apps/ui/layers/exchange-listing/pages/exchange-listing/listing-questionnaires.vue?macro=true";
import { default as estimating_45demandhlyVVlK8R_45Nfs3_451LC8_45CAyI_vWelxjGuF7SGZvkKo4Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/estimating-demand.vue?macro=true";
import { default as market_45maker_45detailsJ0YIOj7yy_f62jiMwccVxuiqIezxNDaPNdcrVJe3dxoMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/market-maker-details.vue?macro=true";
import { default as value_45capturerLdNrKxn1joa_PXUMrLbIuI5IB02TQsEKXb8XWnKKZcMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-capture.vue?macro=true";
import { default as business_45modelkNK_RBLY0f3u3YFw_WRFv6zUXGYqDpgzcqK46u1iMMgMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/business-model.vue?macro=true";
import { default as mission_45visionS_nhHK_yMslRWcQPvaOce3cXQ9Y2JY3gLaqryM9DAOMMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/mission-vision.vue?macro=true";
import { default as value_45creationRDmlZKadvq9pabckPALK5nyryXQHw_Hv_45qxvHZxHeuUMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-creation.vue?macro=true";
import { default as demand_45driversmg1VyIdtUn_Ahi82aboAReK6s90_K6m0gFvXcO6denUMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/modeling/demand-drivers.vue?macro=true";
import { default as request_45for_45quotationsExmFRVyhVXn2BWxOjNFk7OJn_fcqiJEGfg_fyiofenQMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/request-for-quotations.vue?macro=true";
import { default as basic_45informationU9ZE859qj6rVu_450lBaLqhaovnl_CNBCBThXtLP8EEGkMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/basic-information.vue?macro=true";
import { default as estimating_45demandfdBxX_45H_e1TxzJDmEXL0UNjCzgBGwR_YZ_2yv_455vEVwMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/modeling/estimating-demand.vue?macro=true";
import { default as modeling_45sell_45pressureP_45Ux1pL_45PEoZYEdA0_puPjqmO3nZxOgHhamxM88U9esMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-sell-pressure.vue?macro=true";
import { default as token_45emission_45scheduleSaucWoaxmH2xV0UlKnFzOjSeRVY2R80d1Tu55JdjgXcMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-emission-schedule.vue?macro=true";
import { default as product_45market_45fitiyvrQ2kkPV5bY6bpdPtcXbzYJRHjgF7y5t_Yh5qLRiMMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/product-market-fit.vue?macro=true";
import { default as value_45capturewhm9k46UHo8RS_dXJCJ2Q4rg2m7_yNNoDGPdx1JV0rMMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/value-capture.vue?macro=true";
import { default as business_45modelzvEcP4ns4UY8cCyTn7hizmR9rFhW_45siGchkY90hZKaIMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/business-model.vue?macro=true";
import { default as mission_45visionfKszRi8a0AaN_0IZD_sKziWvOZW8xxTGsHsKApfVFvAMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/mission-vision.vue?macro=true";
import { default as value_45creation_1zwehQaQxakWyX7XazJ836s5S0U750GjqJvNhR7XG0Meta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/value-creation.vue?macro=true";
import { default as simulating_45post_45tge_45pops7JOm_IaUZyWzrMTFB930fdnBFKeEwVlESwKozR8TH0gMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-post-tge-pops.vue?macro=true";
import { default as problem_45and_45solutionnnf_45NXRNnlOtD_45NxBjUgiE5fmqe44rfoHLgRUZGQ5jMMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/problem-and-solution.vue?macro=true";
import { default as modeling_45supply_45and_45demand7xvxapfdbWK_yy1D_64lm_iHBsGxiKWbhVFUHrSLI7YMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-supply-and-demand.vue?macro=true";
import { default as simulating_45price_45discoveryN_450TefykgdQ9T_45jlaUkDVkTkdZYNNRqk7lPrvWkoznoMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-price-discovery.vue?macro=true";
import { default as token_45distribution_45scheduleHV_45ncJTn4_RzQPSDRoTXk5QCG1kSTIGeP05dtvA2NSIMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-distribution-schedule.vue?macro=true";
import { default as basic_45informationaG5Umly5HtlXxcNWlHeDrOu0S5xQuIZxGaH60LILU1QMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/basic-information.vue?macro=true";
import { default as modeling_45sell_45pressureFLe_45IQQmEsBXVVDVAgW3hJZtug6EBpLC3fUB6NdsWaIMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/modeling-sell-pressure.vue?macro=true";
import { default as token_45emission_45scheduleaMaw7Heb78mIeppQM7DgzPQf65_Lyj3mrwHfWED1QugMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/modeling/token-emission-schedule.vue?macro=true";
import { default as product_45market_45fitY4zeS37OOtzMBbXjYbLkrnEwtn4abs6hI9LFiPAkf1sMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/product-market-fit.vue?macro=true";
import { default as optimizing_45circulating_45supplyTNJ25e3bHnMMib5F_45WVLpC0KjDWsWyJllhHzZTmg2RAMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/optimizing-circulating-supply.vue?macro=true";
import { default as simulating_45post_45tge_45popsklFBAaGjk2znG7QI7tn5AOv0t9zpa3LF409c_45jnOenYMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/simulating-post-tge-pops.vue?macro=true";
import { default as problem_45and_45solutionzYcPtynXXC2f5dQOvc3XYitVmx2mPZ82ESrcWVxD_lEMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/problem-and-solution.vue?macro=true";
import { default as modeling_45supply_45and_45demandtwvv1Y8D65TIIVm1Rx4OcNWsQ_yhpQg4XZBAlLgBAcoMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/modeling-supply-and-demand.vue?macro=true";
import { default as simulating_45price_45discoverytXFmZtd6PZMNidbxxl1ai90c0__y_7lWk_mfhl5E8aMMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/simulating-price-discovery.vue?macro=true";
import { default as token_45distribution_45schedulemg49dEmiOuConYPi7_0bUwid8uO9hgi_cNBD3EzytQsMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/modeling/token-distribution-schedule.vue?macro=true";
import { default as sanitizing_45tokenomicsO7wTO2l3i6rCsSzsv_vSAEZZqHPKcDlj_45s4Gkx_457oeIMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/sanitizing-tokenomics.vue?macro=true";
import { default as optimizing_45circulating_45supplyKaCbOxFYyaADSNPxikbWEEGuMKXJCoZ8BMSkyfceRrcMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/optimizing-circulating-supply.vue?macro=true";
import { default as fdv_45maximum_45token_45supplyXXNwzGhJLmij1Qknfcq6tBoI8kWwMBmqc585HMmwNZoMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/fdv-maximum-token-supply.vue?macro=true";
import { default as sanitizing_45tokenomicsji15dvNgz1UzaKN4LfKRqU1Xk7zcFcJxa_o2_txH9SsMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/valuation-documenting/sanitizing-tokenomics.vue?macro=true";
import { default as fdv_45maximum_45token_45supplyfTZKNXsqN_45X5rneyreZEBBSSWvjTW_kCgxjZv3PIUOYMeta } from "/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/valuation-documenting/fdv-maximum-token-supply.vue?macro=true";
import { default as component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8Meta } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8 } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexUGlnNBCjC_45_45M8rEH7jSPl8KB5Ws6CVixn2vMxO8hygEMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/dashboard/pages/index.vue")
  },
  {
    name: "gitbook",
    path: "/gitbook",
    component: () => import("/opt/render/project/src/apps/ui/pages/gitbook.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: supportf6ME1XvBVM1LiwcnY0zB45guLOVTMgyyWdoyHUO6pKwMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/pages/support.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsgapW9XLrxsw8hw_45iWdJz1x0TKvFeQc_z_45hi8W3fimM0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account-settings/pages/settings.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexmvsUfjQL_45acudqc21na0tcybq_0hjDR_45hDyGl5qwAloMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/login/index.vue")
  },
  {
    name: "mobile-gate",
    path: "/mobile-gate",
    meta: mobile_45gatekAyyeZfEnhxcqb1T3i53y4mVJQQcdZLXvzjfj4cDuugMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/pages/mobile-gate.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: index3ue27YG0B_45EdcYoaqPa6jEaGDxNhOkhrH9lM4h_45CqzcMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/register/index.vue")
  },
  {
    name: "token-settings",
    path: "/token-settings",
    meta: token_45settingsKimlgdMbFYkAIy913DIxQ5ZoUzJ8xdXhg9nnXGXP2NsMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/dashboard/pages/token-settings.vue")
  },
  {
    name: "magic-link",
    path: "/magic-link",
    meta: indexnIe0ite5Zhb_DxZ7rqbrNl7os0T_nPX_IxD4Mn_459DL4Meta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/magic-link/index.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexs3VXQtOScb7lsbe21EMRW5s_9WK2fC_457KZLOSkUnMR0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/onboarding/index.vue")
  },
  {
    name: "tokenomics",
    path: "/tokenomics",
    meta: indexIrgU1woHyWbPJQLFlS3OIa7WZrzWcVCt0SplteL7mvkMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/tokenomics/index.vue")
  },
  {
    name: "v2-authorization",
    path: "/v2/authorization",
    meta: authorizationMA9q0QGkUq89hDw_40oIFkIXVN9nH9W4uIGDWqndFUwMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/v2/authorization.vue")
  },
  {
    name: "academy-slug",
    path: "/academy/:slug(.*)*",
    meta: _91_46_46_46slug_93AqI5vzdMnbFJeIgoQHjUnn9AlfY5oNUjgbi6jXC6_d0Meta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/pages/academy/[...slug].vue")
  },
  {
    name: "playbook-advanced",
    path: "/playbook-advanced",
    meta: playbook_45advancedkJy3A_45FkhlvkfIkwz8h2mo_yVzktuQftpj4EeV3jMI8Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/dashboard/pages/playbook-advanced.vue")
  },
  {
    name: "password-new",
    path: "/password/new",
    meta: indexrsMIpkuc76ev75_cRP5eIV90A33EjtXVDo6iHL1l53IMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/password/new/index.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: indexgQTfHNlJKHiUW_45SnfSkgGrdyM8TkcErG1Mdvki1lGL0Meta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/verify-email/index.vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    meta: indexCecKR3D_454S2_gPaIm7BXluQxM77M5TH4GbNBdZ4vU4sMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/confirm-email/index.vue")
  },
  {
    name: "playground-api-path",
    path: "/playground/api/path",
    meta: pathZocmFISoG9X7JgwX5_ZX1jaAf7pjfQ6T5K79sTbEMJcMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/playground/pages/playground/api/path.vue")
  },
  {
    name: "token-unlocks",
    path: "/token-unlocks",
    meta: indexchwjBEzU3DYgaDf_4578n3F_MOoOPPonbm_A2SYV9E8dwMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-unlocks/index.vue")
  },
  {
    name: "email-redirect",
    path: "/email-redirect",
    meta: indexXzC_3k_45TTZ0bbWVcKTM8ihm3y1Dl2Qy4qwiQlVm8gxUMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/email-redirect/index.vue")
  },
  {
    name: "page-not-found",
    path: "/page-not-found",
    meta: indexVsJ3Y9CBWwhs_45cRYjB_45_SHjwMCnDMYytgncMMSJX2FAMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/page-not-found/index.vue")
  },
  {
    name: "playground-api",
    path: "/playground/api",
    meta: index9c6D2dkah0lTGE_45TZCBc_45oZGRe3tO_UL2EE40Aw9KOgMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/playground/pages/playground/api/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: index3dyXAQxbkgylQA6DfyHAsw0Ek3Xn4ovpA2DLkuagWUUMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/reset-password/index.vue")
  },
  {
    name: "token-designer",
    path: "/token-designer",
    meta: index7h9mSdnWuZVzQKKviapqrKR4SMHljUvI3GmJmySEh5wMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/index.vue")
  },
  {
    name: "v2-invitation-code",
    path: "/v2/invitation/:code()",
    meta: _91code_93K4qjhvU1JkHGJi49gt8PICET2uXgQpV5xr_45E1ftLI6sMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/v2/invitation/[code].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexWIGryPbaLoed4csmMnyPGFAVo0Mx4squsz1VsenpokYMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/forgot-password/index.vue")
  },
  {
    name: "playground-pinia-path",
    path: "/playground/pinia/path",
    meta: pathkdqzhAXVSx2mIOFNdhslFxYxAelrhOtVi3ebv_VOHdEMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/pages/playground/pinia/path.vue")
  },
  {
    name: "_next-tokenomics",
    path: "/_next/tokenomics",
    meta: index_4579_45AgksIw_45QUfVcXRDsdmSNrco_45fFbYtqA5HhuM8I0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/tokenomics/index.vue")
  },
  {
    name: "exchange-listing",
    path: "/exchange-listing",
    meta: indexXCZmgkw_zoPvGj8xXYGAIekzXTOwcBvk4Sxq_qLW2KMMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/exchange-listing/pages/exchange-listing/index.vue")
  },
  {
    name: "playground-pinia",
    path: "/playground/pinia",
    meta: indexsQESe2YUxjy6I6HSEGd8dBO38PE25zgcZJsBDxV4WqIMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/pages/playground/pinia/index.vue")
  },
  {
    name: "playground-api-reactive",
    path: "/playground/api/reactive",
    meta: reactiveclVfUKJW4han5j8AxGtpd7IR1LuhusFG9vaTvWvhW6EMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/playground/pages/playground/api/reactive.vue")
  },
  {
    name: "playground-api-ui-select",
    path: "/playground/api/ui-select",
    meta: ui_45selectzhcRh60UxVtsEYCFeAgxwdbpAoZ35ilLuBJ9lQrIpQEMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/playground/pages/playground/api/ui-select.vue")
  },
  {
    name: "_next-token-unlocks",
    path: "/_next/token-unlocks",
    meta: indexgXioA2zbhxLLgQ4AZIftbh2MmU2pPtsEEGFzi4bCCsUMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-unlocks/index.vue")
  },
  {
    name: "playground-pinia-mutation",
    path: "/playground/pinia/mutation",
    meta: mutationoNORG0HJO9gpXlVn1xzOFvwBjFSs1Jx60E69Ew97T64Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/pages/playground/pinia/mutation.vue")
  },
  {
    name: "playground-pinia-reactive",
    path: "/playground/pinia/reactive",
    meta: reactiveoYrPPUbKvF2g8ZgZx5t7paUE1V5Tmmb_sHm9x4pmdvwMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/pages/playground/pinia/reactive.vue")
  },
  {
    name: "_next-token-designer",
    path: "/_next/token-designer",
    meta: index23_7ki6oKmkqmRtKn5DXIXW8ihYuuuF8oZuEKlqq7WkMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/index.vue")
  },
  {
    name: "partner-schedule-partner",
    path: "/partner-schedule/:partner()",
    meta: _91partner_93RV_454t1z4eqa4QGXJjjpaX3oPaU60pX6Xr_45dZON6QN0QMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-schedule/[partner].vue")
  },
  {
    name: "playground-pinia-ui-select",
    path: "/playground/pinia/ui-select",
    meta: ui_45selectuun6H21_dxI8_Qu1o5a0EtYIgVfLtZMCHJ3gOwgbc58Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/pages/playground/pinia/ui-select.vue")
  },
  {
    name: "engage-a-market-maker",
    path: "/engage-a-market-maker",
    meta: indexMLKPgoo56OD1lAzgyp33Dy9mMKnzHFSP3ZchdMkAE8EMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/index.vue")
  },
  {
    name: "exchange-listing-listing-fees",
    path: "/exchange-listing/listing-fees",
    meta: listing_45feesKvCPVLJnqQ5gGB1kZAYKyDynQZl5wfpdiETuzjiFaJIMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/exchange-listing/pages/exchange-listing/listing-fees.vue")
  },
  {
    name: "market-maker-monitoring",
    path: "/market-maker-monitoring",
    meta: indexREQFlGg5ET5GjhFr5BWzAitbM49ffAha8_an_4525yKPAMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/index.vue")
  },
  {
    name: "market-maker-monitoring-mode",
    path: "/market-maker-monitoring/:mode()",
    meta: _91mode_93d3GDDH_45Znsf0Ict2mPqGpGTQ2VXqIu7_FW8rBuwpfDwMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/[mode].vue")
  },
  {
    name: "liquidity-launch-designer",
    path: "/liquidity-launch-designer",
    meta: indexT7i_45EDKoWt_6JLJs2N_51HwiGH0dIoidkUsQrU4NpMcMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/liquidity-launch-designer/pages/liquidity-launch-designer/index.vue")
  },
  {
    name: "partner-activity-lead-activity",
    path: "/partner-activity-lead/:activity()",
    meta: _91activity_93aY9u3pwTsAAKK1wAnh2P_45BW8_z6YIY3760pQHu2GDI0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-activity-lead/[activity].vue")
  },
  {
    name: "exchange-listing-exchange-ratings",
    path: "/exchange-listing/exchange-ratings",
    meta: exchange_45ratings3qhKNZYFUKi9z99Hi25aQukC5gJhak66zucNEIGaLkgMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/exchange-listing/pages/exchange-listing/exchange-ratings.vue")
  },
  {
    name: "market-maker-monitoring-dashboard",
    path: "/market-maker-monitoring/dashboard",
    meta: dashboard1sXvtQ_Nj4zVMiDiTqa_45yQiN8bf4TNHB58fur7o48KQMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring/pages/market-maker-monitoring/dashboard.vue")
  },
  {
    name: "share-ticker-projectId-public",
    path: "/share/:ticker()/:projectId()/public",
    meta: publicneX7rM_453smVQpKPKPEUHzi2rTUbH5aoGxiAdf7QU_45CEMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/share/[ticker]/[projectId]/public.vue")
  },
  {
    name: "_next-market-maker-monitoring",
    path: "/_next/market-maker-monitoring",
    meta: indexj_45wMXnTlhesb1NrAqQ0cUxLs_45EN5P5TIwHoFNNVHv8EMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring-next/pages/_next/market-maker-monitoring/index.vue")
  },
  {
    name: "engage-a-market-maker-quote-details",
    path: "/engage-a-market-maker/quote-details",
    meta: quote_45detailsEqhrO5T7bfgk2AuGOzbqE0HabeY0IVgNwmXHj_E3jZkMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/quote-details.vue")
  },
  {
    name: "liquidity-launch-designer-dashboard",
    path: "/liquidity-launch-designer/dashboard",
    meta: dashboardSUEFGG3wicyslSh_45kzWKLpnFn2nZmY8H2PnPzXh3gZ8Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/liquidity-launch-designer/pages/liquidity-launch-designer/dashboard.vue")
  },
  {
    name: "onboarding-organizationId-teammate",
    path: "/onboarding/:organizationId()/teammate",
    meta: teammate4wLPX43bK_45FefeAMhS4c5UzSxijLh7t9A9wuFBw2LpYMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/onboarding/pages/onboarding/[organizationId]/teammate.vue")
  },
  {
    name: "engage-a-market-maker-received-quotes",
    path: "/engage-a-market-maker/received-quotes",
    meta: received_45quotesJNdzbzxNVoks7c706SN1YqfOXmqAX5vD_A0rzDu2oRoMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-quotes.vue")
  },
  {
    name: "liquidity-launch-designer-confirmation",
    path: "/liquidity-launch-designer/confirmation",
    meta: confirmationdqqDTsk1KA4Vs5ENkdzDMJoSBOa_syO7_fEJ4r_ehc8Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/liquidity-launch-designer/pages/liquidity-launch-designer/confirmation.vue")
  },
  {
    name: "token-designer-modeling-demand-drivers",
    path: "/token-designer/modeling/demand-drivers",
    meta: demand_45driversYU_YmicxnAo04vQt5X_45OpqZk7K_45ah0U2yqb1iDaqZFUMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/demand-drivers.vue")
  },
  {
    name: "_next-market-maker-monitoring-dashboard",
    path: "/_next/market-maker-monitoring/dashboard",
    meta: dashboardjVIBLG79m9enzHZ7JoRBwI1Fni8QFYDjRb0HrjENZawMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring-next/pages/_next/market-maker-monitoring/dashboard.vue")
  },
  {
    name: "_next-share-ticker-projectId-public",
    path: "/_next/share/:ticker()/:projectId()/public",
    meta: publickHH2V1hIitT02fElYueTURnFJoNDGxsEl_452pDcYx_45gMMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/share/[ticker]/[projectId]/public.vue")
  },
  {
    name: "engage-a-market-maker-received-requotes",
    path: "/engage-a-market-maker/received-requotes",
    meta: received_45requotes6k3O97vyUDdLsUv67_45eUwWXqoyLe2NMb9d0_74ZvPZAMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-requotes.vue")
  },
  {
    name: "exchange-listing-listing-questionnaires",
    path: "/exchange-listing/listing-questionnaires",
    meta: listing_45questionnairesPBHljWVUBLIplNTn6ol_45JuAm3c8hTYyUu694_tF1OR4Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/exchange-listing/pages/exchange-listing/listing-questionnaires.vue")
  },
  {
    name: "token-designer-modeling-estimating-demand",
    path: "/token-designer/modeling/estimating-demand",
    meta: estimating_45demandhlyVVlK8R_45Nfs3_451LC8_45CAyI_vWelxjGuF7SGZvkKo4Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/estimating-demand.vue")
  },
  {
    name: "engage-a-market-maker-market-maker-details",
    path: "/engage-a-market-maker/market-maker-details",
    meta: market_45maker_45detailsJ0YIOj7yy_f62jiMwccVxuiqIezxNDaPNdcrVJe3dxoMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/market-maker-details.vue")
  },
  {
    name: "token-designer-token-profile-value-capture",
    path: "/token-designer/token-profile/value-capture",
    meta: value_45capturerLdNrKxn1joa_PXUMrLbIuI5IB02TQsEKXb8XWnKKZcMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-capture.vue")
  },
  {
    name: "token-designer-token-profile-business-model",
    path: "/token-designer/token-profile/business-model",
    meta: business_45modelkNK_RBLY0f3u3YFw_WRFv6zUXGYqDpgzcqK46u1iMMgMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/business-model.vue")
  },
  {
    name: "token-designer-token-profile-mission-vision",
    path: "/token-designer/token-profile/mission-vision",
    meta: mission_45visionS_nhHK_yMslRWcQPvaOce3cXQ9Y2JY3gLaqryM9DAOMMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/mission-vision.vue")
  },
  {
    name: "token-designer-token-profile-value-creation",
    path: "/token-designer/token-profile/value-creation",
    meta: value_45creationRDmlZKadvq9pabckPALK5nyryXQHw_Hv_45qxvHZxHeuUMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-creation.vue")
  },
  {
    name: "_next-token-designer-modeling-demand-drivers",
    path: "/_next/token-designer/modeling/demand-drivers",
    meta: demand_45driversmg1VyIdtUn_Ahi82aboAReK6s90_K6m0gFvXcO6denUMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/modeling/demand-drivers.vue")
  },
  {
    name: "engage-a-market-maker-request-for-quotations",
    path: "/engage-a-market-maker/request-for-quotations",
    meta: request_45for_45quotationsExmFRVyhVXn2BWxOjNFk7OJn_fcqiJEGfg_fyiofenQMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/request-for-quotations.vue")
  },
  {
    name: "token-designer-token-profile-basic-information",
    path: "/token-designer/token-profile/basic-information",
    meta: basic_45informationU9ZE859qj6rVu_450lBaLqhaovnl_CNBCBThXtLP8EEGkMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/basic-information.vue")
  },
  {
    name: "_next-token-designer-modeling-estimating-demand",
    path: "/_next/token-designer/modeling/estimating-demand",
    meta: estimating_45demandfdBxX_45H_e1TxzJDmEXL0UNjCzgBGwR_YZ_2yv_455vEVwMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/modeling/estimating-demand.vue")
  },
  {
    name: "token-designer-adjusting-modeling-sell-pressure",
    path: "/token-designer/adjusting/modeling-sell-pressure",
    meta: modeling_45sell_45pressureP_45Ux1pL_45PEoZYEdA0_puPjqmO3nZxOgHhamxM88U9esMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-sell-pressure.vue")
  },
  {
    name: "token-designer-modeling-token-emission-schedule",
    path: "/token-designer/modeling/token-emission-schedule",
    meta: token_45emission_45scheduleSaucWoaxmH2xV0UlKnFzOjSeRVY2R80d1Tu55JdjgXcMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-emission-schedule.vue")
  },
  {
    name: "token-designer-token-profile-product-market-fit",
    path: "/token-designer/token-profile/product-market-fit",
    meta: product_45market_45fitiyvrQ2kkPV5bY6bpdPtcXbzYJRHjgF7y5t_Yh5qLRiMMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/product-market-fit.vue")
  },
  {
    name: "_next-token-designer-token-profile-value-capture",
    path: "/_next/token-designer/token-profile/value-capture",
    meta: value_45capturewhm9k46UHo8RS_dXJCJ2Q4rg2m7_yNNoDGPdx1JV0rMMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/value-capture.vue")
  },
  {
    name: "_next-token-designer-token-profile-business-model",
    path: "/_next/token-designer/token-profile/business-model",
    meta: business_45modelzvEcP4ns4UY8cCyTn7hizmR9rFhW_45siGchkY90hZKaIMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/business-model.vue")
  },
  {
    name: "_next-token-designer-token-profile-mission-vision",
    path: "/_next/token-designer/token-profile/mission-vision",
    meta: mission_45visionfKszRi8a0AaN_0IZD_sKziWvOZW8xxTGsHsKApfVFvAMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/mission-vision.vue")
  },
  {
    name: "_next-token-designer-token-profile-value-creation",
    path: "/_next/token-designer/token-profile/value-creation",
    meta: value_45creation_1zwehQaQxakWyX7XazJ836s5S0U750GjqJvNhR7XG0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/value-creation.vue")
  },
  {
    name: "token-designer-adjusting-simulating-post-tge-pops",
    path: "/token-designer/adjusting/simulating-post-tge-pops",
    meta: simulating_45post_45tge_45pops7JOm_IaUZyWzrMTFB930fdnBFKeEwVlESwKozR8TH0gMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-post-tge-pops.vue")
  },
  {
    name: "token-designer-token-profile-problem-and-solution",
    path: "/token-designer/token-profile/problem-and-solution",
    meta: problem_45and_45solutionnnf_45NXRNnlOtD_45NxBjUgiE5fmqe44rfoHLgRUZGQ5jMMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/problem-and-solution.vue")
  },
  {
    name: "token-designer-adjusting-modeling-supply-and-demand",
    path: "/token-designer/adjusting/modeling-supply-and-demand",
    meta: modeling_45supply_45and_45demand7xvxapfdbWK_yy1D_64lm_iHBsGxiKWbhVFUHrSLI7YMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-supply-and-demand.vue")
  },
  {
    name: "token-designer-adjusting-simulating-price-discovery",
    path: "/token-designer/adjusting/simulating-price-discovery",
    meta: simulating_45price_45discoveryN_450TefykgdQ9T_45jlaUkDVkTkdZYNNRqk7lPrvWkoznoMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-price-discovery.vue")
  },
  {
    name: "token-designer-modeling-token-distribution-schedule",
    path: "/token-designer/modeling/token-distribution-schedule",
    meta: token_45distribution_45scheduleHV_45ncJTn4_RzQPSDRoTXk5QCG1kSTIGeP05dtvA2NSIMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-distribution-schedule.vue")
  },
  {
    name: "_next-token-designer-token-profile-basic-information",
    path: "/_next/token-designer/token-profile/basic-information",
    meta: basic_45informationaG5Umly5HtlXxcNWlHeDrOu0S5xQuIZxGaH60LILU1QMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/basic-information.vue")
  },
  {
    name: "_next-token-designer-adjusting-modeling-sell-pressure",
    path: "/_next/token-designer/adjusting/modeling-sell-pressure",
    meta: modeling_45sell_45pressureFLe_45IQQmEsBXVVDVAgW3hJZtug6EBpLC3fUB6NdsWaIMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/modeling-sell-pressure.vue")
  },
  {
    name: "_next-token-designer-modeling-token-emission-schedule",
    path: "/_next/token-designer/modeling/token-emission-schedule",
    meta: token_45emission_45scheduleaMaw7Heb78mIeppQM7DgzPQf65_Lyj3mrwHfWED1QugMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/modeling/token-emission-schedule.vue")
  },
  {
    name: "_next-token-designer-token-profile-product-market-fit",
    path: "/_next/token-designer/token-profile/product-market-fit",
    meta: product_45market_45fitY4zeS37OOtzMBbXjYbLkrnEwtn4abs6hI9LFiPAkf1sMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/product-market-fit.vue")
  },
  {
    name: "token-designer-adjusting-optimizing-circulating-supply",
    path: "/token-designer/adjusting/optimizing-circulating-supply",
    meta: optimizing_45circulating_45supplyTNJ25e3bHnMMib5F_45WVLpC0KjDWsWyJllhHzZTmg2RAMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/optimizing-circulating-supply.vue")
  },
  {
    name: "_next-token-designer-adjusting-simulating-post-tge-pops",
    path: "/_next/token-designer/adjusting/simulating-post-tge-pops",
    meta: simulating_45post_45tge_45popsklFBAaGjk2znG7QI7tn5AOv0t9zpa3LF409c_45jnOenYMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/simulating-post-tge-pops.vue")
  },
  {
    name: "_next-token-designer-token-profile-problem-and-solution",
    path: "/_next/token-designer/token-profile/problem-and-solution",
    meta: problem_45and_45solutionzYcPtynXXC2f5dQOvc3XYitVmx2mPZ82ESrcWVxD_lEMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/token-profile/problem-and-solution.vue")
  },
  {
    name: "_next-token-designer-adjusting-modeling-supply-and-demand",
    path: "/_next/token-designer/adjusting/modeling-supply-and-demand",
    meta: modeling_45supply_45and_45demandtwvv1Y8D65TIIVm1Rx4OcNWsQ_yhpQg4XZBAlLgBAcoMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/modeling-supply-and-demand.vue")
  },
  {
    name: "_next-token-designer-adjusting-simulating-price-discovery",
    path: "/_next/token-designer/adjusting/simulating-price-discovery",
    meta: simulating_45price_45discoverytXFmZtd6PZMNidbxxl1ai90c0__y_7lWk_mfhl5E8aMMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/simulating-price-discovery.vue")
  },
  {
    name: "_next-token-designer-modeling-token-distribution-schedule",
    path: "/_next/token-designer/modeling/token-distribution-schedule",
    meta: token_45distribution_45schedulemg49dEmiOuConYPi7_0bUwid8uO9hgi_cNBD3EzytQsMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/modeling/token-distribution-schedule.vue")
  },
  {
    name: "token-designer-valuation-documenting-sanitizing-tokenomics",
    path: "/token-designer/valuation-documenting/sanitizing-tokenomics",
    meta: sanitizing_45tokenomicsO7wTO2l3i6rCsSzsv_vSAEZZqHPKcDlj_45s4Gkx_457oeIMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/sanitizing-tokenomics.vue")
  },
  {
    name: "_next-token-designer-adjusting-optimizing-circulating-supply",
    path: "/_next/token-designer/adjusting/optimizing-circulating-supply",
    meta: optimizing_45circulating_45supplyKaCbOxFYyaADSNPxikbWEEGuMKXJCoZ8BMSkyfceRrcMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/adjusting/optimizing-circulating-supply.vue")
  },
  {
    name: "token-designer-valuation-documenting-fdv-maximum-token-supply",
    path: "/token-designer/valuation-documenting/fdv-maximum-token-supply",
    meta: fdv_45maximum_45token_45supplyXXNwzGhJLmij1Qknfcq6tBoI8kWwMBmqc585HMmwNZoMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/fdv-maximum-token-supply.vue")
  },
  {
    name: "_next-token-designer-valuation-documenting-sanitizing-tokenomics",
    path: "/_next/token-designer/valuation-documenting/sanitizing-tokenomics",
    meta: sanitizing_45tokenomicsji15dvNgz1UzaKN4LfKRqU1Xk7zcFcJxa_o2_txH9SsMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/valuation-documenting/sanitizing-tokenomics.vue")
  },
  {
    name: "_next-token-designer-valuation-documenting-fdv-maximum-token-supply",
    path: "/_next/token-designer/valuation-documenting/fdv-maximum-token-supply",
    meta: fdv_45maximum_45token_45supplyfTZKNXsqN_45X5rneyreZEBBSSWvjTW_kCgxjZv3PIUOYMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer-next/pages/_next/token-designer/valuation-documenting/fdv-maximum-token-supply.vue")
  },
  {
    name: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8Meta?.name,
    path: "/privacy-policy",
    component: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8
  },
  {
    name: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8Meta?.name,
    path: "/terms-of-use",
    component: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8
  }
]