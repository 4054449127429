import type { Router } from 'vue-router'
import { usePostHog } from '#core/composables/usePostHog'

const TokenDesignPaths = [
  'share',
  'token-designer',
  'token-unlocks',
  'tokenomics',
]

const TokenDesignerNextPrefix = '_next'

/**
 * For Token Designer v2 we need to swap the paths for the regular token designer for a cohesive experience.
 */
function swapTokenDesignerPaths(router: Router, v2 = false) {
  const routes = router.getRoutes()
  router.clearRoutes()
  // re-add routes
  routes.forEach((route) => {
    const isV1Path = TokenDesignPaths.some(path => route.path.startsWith(`/${path}`))
    const isV2Path = TokenDesignPaths.some(path => route.path.startsWith(`/${TokenDesignerNextPrefix}/${path}`))
    if (v2) {
      if (isV1Path) {
        return
      }
      if (isV2Path) {
        route.path = route.path.replace(`/${TokenDesignerNextPrefix}/`, '/')
        if (route.meta.layout && !route.meta.layout.startsWith('next-')) {
          route.meta.layout = `next-${route.meta.layout}`
        }
      }
    }
    else if (isV2Path) {
      // remove v2 paths
      return
    }
    router.addRoute(route)
  })
}

export default defineNuxtPlugin({
  async setup() {
    const router = useRouter()

    const featureFlagPromise = new Promise<boolean>((resolve) => {
      usePostHog((posthog) => {
        posthog.onFeatureFlags(async () => {
          resolve((posthog.isFeatureEnabled('token-designer-v2')) || false)
        })
      })
    }).catch(() => false)
    if (TokenDesignPaths.some(p => router.currentRoute.value.path.startsWith(`/${p}`))) {
      swapTokenDesignerPaths(router, await featureFlagPromise)
      return
    }
    featureFlagPromise.then((v) => {
      swapTokenDesignerPaths(router, v)
    })
  },
})
