import { getUserPermissions } from '@forgd/shared/permissions'

export const useCurrentUserPermissionsStore = defineStore('currentUserPermissions', () => {
  const auth = useAuth()
  const userPermissions = computed(() => {
    if (!auth.me) {
      return null
    }

    return getUserPermissions(auth.me.id)
  })
  return { userPermissions }
})
