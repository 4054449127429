import type { Me } from '#core/layers/auth/composables/useAuth'
import { MemberStatusEnum } from '@forgd/supabase'

const dashboardPath = process.env.DASHBOARD_PATH || '/'
const onboardingPath = process.env.ONBOARDING_PATH || '/onboarding'

/**
 * There are 6 scenarios:
 * 1. User has OWN memberStatus=active organization with no onboarded projects (no projects at all) → redirect to owner onboarding
 * 2. User has OWN memberStatus=active organization with an onboarded project → redirect to dashboard
 * 3. User has INVITED memberStatus=active organization with no onboarded projects → redirect to teammate onboarding with organizationId
 * 4. User has INVITED memberStatus=active organization with an onboarded project → redirect to dashboard
 * 5. There's route.query.organizationId → redirect to teammate onboarding with organizationId
 * 6. User has INVITED memberStatus=invited organization → redirect to teammate onboarding with organizationId
 */

export function inferOnboardingState(me: Me) {
  // case when we need to redirect to teammate onboarding with organizationId (6)
  const invitedOrganization = me.organizations.find(
    org => org.memberStatus === MemberStatusEnum.Invited && org.ownerUserId !== me.id,
  )
  if (invitedOrganization) {
    return {
      scenario: 6,
      redirectTo: `${onboardingPath}/${invitedOrganization.id}/teammate`,
    }
  }

  // case when we need to redirect to teammate onboarding with organizationId (3)
  const activeInvitedOrganization = me.organizations.find(
    org => org.memberStatus === MemberStatusEnum.Active && org.ownerUserId !== me.id && !org.projects.length,
  )
  if (activeInvitedOrganization) {
    return {
      scenario: 3,
      redirectTo: `${onboardingPath}/${activeInvitedOrganization.id}/teammate`,
    }
  }

  // case when we need to redirect to dashboard (2, 4)
  const activeOrganizationWithOnboardedProject = me.organizations.find(
    org => org.memberStatus === MemberStatusEnum.Active && org.projects.length,
  )
  if (activeOrganizationWithOnboardedProject) {
    return {
      scenario: 2,
      redirectTo: dashboardPath,
    }
  }

  // case when we need to redirect to owner onboarding (1)
  const activeOwnOrganizationWithoutOnboardedProjects = me.organizations.find(
    org => org.memberStatus === MemberStatusEnum.Active && org.ownerUserId === me.id && (!org.projects.length || org.projects.some(p => !p.isOnboarded)),
  )
  if (activeOwnOrganizationWithoutOnboardedProjects) {
    return {
      scenario: 1,
      redirectTo: onboardingPath,
    }
  }

  throw new Error('No onboarding state found')
}

export function inferSelectedProject(me: Me) {
  // #1 active project which they are an owner of
  const ownOrgWithProject = me.organizations.find(p => p.memberStatus === MemberStatusEnum.Active && p.ownerUserId === me.id && p.projects.length > 0)
  if (ownOrgWithProject) {
    return ownOrgWithProject.projects[0]
  }
  // #2 first active project they are a member of
  const anyOrgWithProject = me.organizations.find(p => p.memberStatus === MemberStatusEnum.Active && p.projects.length > 0)
  if (anyOrgWithProject) {
    return anyOrgWithProject.projects[0]
  }
  // #3 sounds like a job for onboarding
  return null
}
