import type { PostHog } from 'posthog-js'

let postHogInstance: PostHog | undefined
let postHogLoadPromise: Promise<PostHog | undefined> | undefined

async function initPostHog() {
  if (postHogLoadPromise) {
    return postHogLoadPromise
  }
  if (postHogInstance) {
    return postHogInstance
  }
  // eslint-disable-next-line no-async-promise-executor
  return postHogLoadPromise = new Promise<PostHog | undefined>(async (resolve) => {
    const config = useRuntimeConfig()
    const posthog = await import('posthog-js').then(module => module.default)
    postHogInstance = posthog.init(config.public.posthog.publicKey, {
      api_host: '/ingest',
      autocapture: !import.meta.dev,
      capture_pageview: false,
      disable_surveys: true,
      disable_session_recording: import.meta.dev,
      enable_heatmaps: !import.meta.dev,
      loaded: () => {
        if (import.meta.dev) {
          // ph.debug()
          // ph.opt_out_capturing() // might need capturing for the release condition on feature flags
        }
      },
    })
    resolve(postHogInstance)
  })
}

export function usePostHog(cb: (ph: PostHog) => void | Promise<void>) {
  initPostHog().then(ph => ph && cb(ph))
}
