export const checksums = {
  "pages": "v3.3.0--w4opbeovxb",
  "snippets": "v3.3.0--uMygfsBgJr"
}
export const checksumsStructure = {
  "pages": "Vys3THAWLg",
  "snippets": "uchzGVFjeP"
}

export const tables = {
  "pages": "_content_pages",
  "snippets": "_content_snippets",
  "info": "_content_info"
}

export default {
  "pages": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "introduction": "boolean",
      "headline": "string",
      "icon": "string",
      "wistiaId": "string"
    }
  },
  "snippets": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json"
    }
  },
  "info": {
    "type": "data",
    "fields": {}
  }
}