/**
 * Pluralisation function
 *
 * @param value         A number, array or undefined value
 * @param word          A single word, or singular and plural form separated by a non-word character
 * @param includeValue  Include the number in the return
 */
export function plural(value: number | any[] | undefined, word: string, includeValue = true): string {
  const [one, some] = word.split(/\W/)
  const plural = some || `${one}s`
  const num = Array.isArray(value)
    ? value.length
    : value
  const output = num === 1
    ? one
    : plural
  return includeValue
    ? `${num} ${output}`
    : output
}

export function toTitleCase(text: string): string {
  return text
    .trim()
    .replace(/_/g, ' ')
    .replace(/([A-Z])/g, ' $1')
    .replace(/\s+/g, ' ')
    .replace(/\b(\w)/g, (str: string) => str.toUpperCase())
    .trim()
}
