type SwitchDialogType = 'confirm-switch' | 'require-switch'
type SwitchDialogState = 'prompt' | 'processing' | 'success'

export const usePermissionsDialogStore = defineStore('permissions-dialog', () => {
  const type = ref<SwitchDialogType>()
  const state = ref<SwitchDialogState>('prompt')
  const isForbiddenModalOpen = ref(false)
  const isSwitchModalOpen = ref(false)

  function showConfirmSwitch() {
    type.value = 'confirm-switch'
    isSwitchModalOpen.value = true
  }

  function showRequireSwitch() {
    type.value = 'require-switch'
    isSwitchModalOpen.value = true
  }

  function showForbiddenTeammateAction() {
    isForbiddenModalOpen.value = true
  }

  async function closeSwitchModal() {
    isSwitchModalOpen.value = false

    // Prevents flashing an empty state
    await new Promise(resolve => setTimeout(resolve, 200))
    state.value = 'prompt'
    type.value = undefined
  }

  function closeForbiddenModal() {
    isForbiddenModalOpen.value = false
  }

  return {
    state,
    type,
    isSwitchModalOpen,
    isForbiddenModalOpen,
    closeForbiddenModal,
    closeSwitchModal,
    showConfirmSwitch,
    showRequireSwitch,
    showForbiddenTeammateAction,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePermissionsDialogStore, import.meta.hot))
}
