import { usePermissions } from '#auth/composables/usePermissions'
import { usePermissionsDialogStore } from '#auth/composables/usePermissionsDialogStore'
import { subject } from '@casl/ability'

export default defineNuxtPlugin({
  setup(nuxtApp) {
    const { checkPermission } = usePermissions()
    const { showForbiddenTeammateAction } = usePermissionsDialogStore()
    const auth = useAuth()
    const router = useRouter()
    // blocks nav click
    router.beforeEach((to) => {
      if (to.path === '/partner-activity-lead/amm-2' && !checkPermission('read', subject('MarketMaker:AMM2', { ownerUserId: auth.organization!.ownerUserId, isResearch: auth.project!.isResearch }))) {
        return false
      }
    })
    nuxtApp.hooks.hook('forgd:fetch:unauthorized', async () => {
      if (!auth.isOrganizationOwner) {
        showForbiddenTeammateAction()
      }
    })
  },
})
