import type { CanParameters } from '@casl/ability'
import type { AppAbilities } from '@forgd/shared/permissions'
import { getNormalizedSubject, REQUIRE_ORG_OWNER, REQUIRE_SWITCH_RESEARCH } from '@forgd/shared/permissions'
import { useCurrentUserPermissionsStore } from './useCurrentUserPermissionsStore'
import { usePermissionsDialogStore } from './usePermissionsDialogStore'

export function usePermissions() {
  const { userPermissions } = storeToRefs(useCurrentUserPermissionsStore())
  const permissionsDialog = usePermissionsDialogStore()

  function checkPermission(
    ...args: CanParameters<AppAbilities>
  ): boolean {
    if (userPermissions.value?.can(...args)) {
      return true
    }
    const action = args[0]
    const normalizedSubject = getNormalizedSubject(args[1])

    // @ts-expect-error: TODO(@elgutierrez) fix this
    const rule = userPermissions.value?.relevantRuleFor(action, normalizedSubject)
    if (rule?.reason === REQUIRE_SWITCH_RESEARCH) {
      permissionsDialog.showRequireSwitch()
    }
    else if (rule?.reason === REQUIRE_ORG_OWNER) {
      permissionsDialog.showForbiddenTeammateAction()
    }

    return false
  }

  return { checkPermission }
}
