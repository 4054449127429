import type { InputSize } from '../internal/common'
import { computed, type ComputedRef, inject, provide } from 'vue'

export const labelWidths = {
  xs: 'w-1/4',
  sm: 'w-1/3',
  md: 'w-1/2',
  lg: 'w-2/3',
} as const

export type FormOrientation = 'vertical' | 'horizontal'

export type LabelWidth = keyof typeof labelWidths | `w-${string}`

export interface FormLayout {
  orientation?: FormOrientation
  labelWidth?: LabelWidth
  inputSize?: InputSize
}

export const formLayoutDefaults: FormLayout = {
  orientation: 'vertical',
  labelWidth: 'w-1/2',
  inputSize: 'lg',
}

export const FormLayoutKey = Symbol('form-layout')

/**
 * Manage form layout at different levels of the component tree
 *
 * @param props
 */
export function useFormLayout<T extends Partial<FormLayout>>(props: T) {
  // Get parent context or use defaults
  const parentLayout = inject<ComputedRef<FormLayout> | FormLayout>(
    FormLayoutKey,
    formLayoutDefaults,
  )

  // Create merged context that automatically watches props
  const layout = computed<FormLayout>(() => {
    const parentValues = 'value' in parentLayout
      ? parentLayout.value
      : parentLayout

    // Extract FormLayout properties from props
    const propsContext = {
      orientation: props.orientation,
      labelWidth: props.labelWidth,
      inputSize: props.inputSize,
    }

    // Filter out undefined values from propsContext
    const filteredProps = Object.fromEntries(
      Object.entries(propsContext).filter(([_, v]) => v !== undefined),
    )

    // Merge parent values with props
    return {
      ...parentValues,
      ...filteredProps,
    }
  })

  // Provide the merged layout
  provide(FormLayoutKey, layout)

  // Return useful computed properties
  return {
    orientation: computed(() => layout.value.orientation),
    labelWidth: computed(() => layout.value.labelWidth),
    inputSize: computed(() => layout.value.inputSize || formLayoutDefaults.inputSize),
  }
}
