import type { NavLink } from '#core/utils/nav'

interface ParsedContentFile {
  page?: boolean
  path: string
  title: string
  children?: ParsedContentFile[]
}

export function firstChildToIfDir(item: ParsedContentFile) {
  if (!item.page && item.children?.[0]) {
    return item.children[0]?.path
  }
  return item.path
}

let academyNavPromise: Promise<{ data: Ref<AcademyNavGroup[]> }> | null = null
const academyNav = ref<AcademyNavGroup[]>([])

type MergedNav = ParsedContentFile & NavLink
export type AcademyContentFile = MergedNav & {
  wistiaId?: string
  body: {
    value: any[]
  }
}
export interface AcademyNavGroup extends AcademyContentFile {
  children?: AcademyContentFile[]
}

export function fetchAcademyNav() {
  interface AcademyNavResponse {
    data: Ref<AcademyNavGroup[]>
  }
  academyNavPromise = academyNavPromise || new Promise<AcademyNavResponse>((resolve) => {
    queryCollectionNavigation('pages', ['icon']).then((res: { children: AcademyNavGroup[] }[]) => {
      academyNav.value = res[0].children
      resolve({ data: academyNav })
    })
  })
  return { data: academyNav, ...academyNavPromise } as AcademyNavResponse & Promise<AcademyNavResponse>
}

export const forgdAcademyNav = computed<NavLink[]>(() => {
  if (!academyNav.value?.length) {
    fetchAcademyNav() // trigger nav load
    return []
  }

  return [
    ...academyNav.value.map((item): NavLink => {
      return {
        ...item,
        label: item.title,
        to: item.path,
        children: (item.children || []).map((child): NavLink => ({
          ...child,
          label: child.title,
          to: firstChildToIfDir(child),
        })),
      }
    }),
    {
      label: 'Other Readings',
      icon: 'i-heroicons-newspaper' as const,
      children: [
        { label: 'Forgd Products FAQs', to: 'https://forgd.com/faq#tools' },
        { label: 'About Forgd FAQs', to: 'https://forgd.com/faq#about' },
        { label: 'Forgd Blog', to: 'https://forgd.com/blog' },
      ],
    },
  ]
})
