export default defineNuxtPlugin(async (nuxtApp) => {
  if (import.meta.dev) {
    return
  }
  const app = nuxtApp.vueApp
  const router = useRouter()
  const { env, render, sentry } = useRuntimeConfig().public
  const environment = render.isPullRequest
    ? render.serviceName
    : env
  await import('@sentry/vue').then((Sentry) => {
    Sentry.init({
      ...sentry,
      app,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      environment,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [/^https:\/\/api\.forgd\.com/, /^https:\/\/dev-api\.forgd\.com/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  })
})
