import {
  AbilityBuilder,
  detectSubjectType,
  type ForcedSubject,
  type InferSubjects,
  type MatchConditions,
  PureAbility,
} from '@casl/ability'

// Helper type to force a subject to be a specific type
type CaslSubject<T, S extends string> = InferSubjects<T & ForcedSubject<S>>
type AppAbility<Action extends string, Subject extends string, Context> = [Action, CaslSubject<Context, Subject>]

type ResearchAndOrgOwner = {
  isResearch: boolean
  ownerUserId: string
}

type ProjectAbilities =
  | AppAbility<'write', 'Project', ResearchAndOrgOwner>
  | AppAbility<'convertFromResearch', 'Project', ResearchAndOrgOwner>

type RFQAbilities = AppAbility<'write', 'RFQ', ResearchAndOrgOwner>

type TokenDesignerAbilities = AppAbility<'write', 'TokenDesigner', ResearchAndOrgOwner>

type MarketMakerAbilities =
  | AppAbility<'write', 'MarketMaker', ResearchAndOrgOwner>
  | AppAbility<'read', 'MarketMaker:AMM2', ResearchAndOrgOwner>

type TokenSettingsAbilities = AppAbility<'write', 'TokenSettings', ResearchAndOrgOwner>

type TeammateAbilities =
  | AppAbility<'write', 'Teammate', ResearchAndOrgOwner>
  | AppAbility<'delete', 'Teammate', ResearchAndOrgOwner>

// Define the allowed action-subject combinations
export type AppAbilities =
  | RFQAbilities
  | ProjectAbilities
  | TokenDesignerAbilities
  | MarketMakerAbilities
  | TokenSettingsAbilities
  | TeammateAbilities

type ExtractSubject<T> = T extends [string, infer Subject] ? Subject : never

// Enables extracting the subjects type from the ability
type AppSubjects = ExtractSubject<AppAbilities>

// Generate the union of all possible subject types as literal strings
export type SubjectTypes = AppSubjects extends { __caslSubjectType__: infer S } ? S : never

/**
 * Creates a new ability builder that's type-safe and uses the AppAbility type.
 */
export function createAbilityBuilder() {
  return new AbilityBuilder<PureAbility<AppAbilities, MatchConditions>>(PureAbility)
}

/**
 * Type-safe conditions matcher for function-based conditions; It overrides the default matcher
 * that is used by casl to match conditions (MongoDB-like).
 */
export const PermissionConditionsMatcher = (matchConditions: MatchConditions) => matchConditions

// This method is used to get the normalized subject type from the ability (an union of all possible subject types)
export function getNormalizedSubject(subject: AppSubjects): AppSubjects {
  if (typeof subject === 'object' && subject !== null && '__caslSubjectType' in subject) {
    return subject.__caslSubjectType as AppSubjects
  }

  if (typeof subject === 'object' && subject !== null) {
    return detectSubjectType(subject) as AppSubjects
  }

  return subject as AppSubjects
}

export const REQUIRE_SWITCH_RESEARCH = 'require-switch-research'
export const REQUIRE_ORG_OWNER = 'require-org-owner'

const shouldNotBeResearch = ({ isResearch }: ResearchAndOrgOwner) => !isResearch
const shouldBeOrgOwner =
  (userId: string) =>
  ({ ownerUserId }: ResearchAndOrgOwner) =>
    ownerUserId === userId

/**
 * Builds and returns the permission set for a specific user.
 *
 * This function creates a complete set of permissions (abilities) for the specified user,
 * taking into account various conditions like research mode and organization ownership.
 * Each permission rule consists of an action (e.g., 'write', 'read'), a subject (e.g., 'Project', 'RFQ'),
 * and conditions that must be met for the permission to be granted. We can use the `because` method to add a reason for the permission.
 * so that we can use it in the UI to show the user why they don't have access to a certain action.
 *
 * @param userId - The ID of the user for whom to build permissions
 * @returns A PureAbility instance containing all the user's permissions
 */
export function getUserPermissions(userId: string) {
  const { can: allow, build } = createAbilityBuilder()

  allow('write', 'Project', shouldNotBeResearch).because(REQUIRE_SWITCH_RESEARCH)
  allow('convertFromResearch', 'Project', shouldBeOrgOwner(userId)).because(REQUIRE_ORG_OWNER)

  allow('write', 'TokenDesigner', shouldNotBeResearch).because(REQUIRE_SWITCH_RESEARCH)
  allow('write', 'TokenDesigner', shouldBeOrgOwner(userId)).because(REQUIRE_ORG_OWNER)

  allow('write', 'TokenSettings', shouldNotBeResearch).because(REQUIRE_SWITCH_RESEARCH)
  allow('write', 'TokenSettings', shouldBeOrgOwner(userId)).because(REQUIRE_ORG_OWNER)

  allow('write', 'RFQ', shouldNotBeResearch).because(REQUIRE_SWITCH_RESEARCH)
  allow('write', 'RFQ', shouldBeOrgOwner(userId)).because(REQUIRE_ORG_OWNER)

  allow('write', 'Teammate', shouldBeOrgOwner(userId)).because(REQUIRE_ORG_OWNER)
  allow('delete', 'Teammate', shouldBeOrgOwner(userId)).because(REQUIRE_ORG_OWNER)

  allow('write', 'MarketMaker', shouldNotBeResearch).because(REQUIRE_SWITCH_RESEARCH)
  allow('read', 'MarketMaker:AMM2', shouldBeOrgOwner(userId)).because(REQUIRE_ORG_OWNER)

  return build({ conditionsMatcher: PermissionConditionsMatcher })
}
