import type { paths } from '@forgd/contract/openapi'
import type { PostHog } from 'posthog-js'
import { defu } from 'defu'
import { usePostHog } from './usePostHog'

// workaround for issue importing tracing type from contract
type TracingEventType = NonNullable<paths['/events']['post']['requestBody']>['content']['application/json'][number]

const MaxQueueSize = 10

const logger = useDevLogger('tracer')

function createTracer(options?: { defaultEventData?: TracingEventType['eventData'] }) {
  const defaults = defu(options?.defaultEventData || {}, {
    userAgent: navigator.userAgent,
  })
  const auth = useAuth()
  const route = useRoute()
  const ph = new Promise((resolve) => {
    usePostHog(ph => resolve(ph))
  })

  function thenPh(fn: ((ph: PostHog) => void)) {
    ph.then((ph) => {
      ph && fn(ph)
    })
  }

  const queue: TracingEventType[] = []
  let flushQueued: boolean = false

  async function flush() {
    const body = [...queue.splice(0, MaxQueueSize)]
      .map(event => defu(event, { eventData: defaults }))
    if (import.meta.dev) {
      logger.debug('Flushing queue', body)
    }
    else if (body.length) {
      await useQuery('/events', { method: 'post', params: { body } })
    }
    flushQueued = false
    if (queue.length > 0) {
      queueFlush()
    }
  }

  function queueFlush() {
    if (!flushQueued) {
      flushQueued = true
      onNuxtReady(() => {
        flush()
      })
    }
  }

  return {
    pageView(data?: TracingEventType['eventData']) {
      if (!auth.loggedIn) {
        return
      }
      const payload = defu(
        { eventData: data },
        { eventData: { path: route.fullPath }, projectId: auth.project?.id, eventType: 'page_view' },
      )
      logger.debug('Page view', payload)
      queue.push(payload)
      queueFlush()

      // Send to PostHog
      thenPh(ph => ph.capture('$pageview', {
        ...payload.eventData,
        project_id: auth.project?.id,
      }))
    },
    custom(eventType: string, data: TracingEventType['eventData']) {
      if (!auth.loggedIn) {
        return
      }
      const payload = defu(
        { eventData: data },
        { eventData: { path: route.fullPath }, projectId: auth.project?.id, eventType },
      )
      logger.debug(`Custom: ${eventType}`, payload)
      queue.push(payload)
      queueFlush()

      // Send to PostHog
      thenPh(ph => ph.capture(eventType, {
        ...payload.eventData,
        project_id: auth.project?.id,
      }))
    },
    identify(id: string, { email }: { email?: string }) {
      logger.debug('identify', { id })
      thenPh(ph => ph.identify(id, { email }))
    },
  }
}

type Tracer = ReturnType<typeof createTracer>

export function useTracer(): Tracer {
  const nuxtApp = useNuxtApp()
  // singleton
  if (!nuxtApp._tracer) {
    nuxtApp._tracer = createTracer()
  }
  return nuxtApp._tracer as Tracer
}
