import type { AsyncDataRequestStatus } from '#app'
import type { paths } from '@forgd/contract/openapi'
import type { NitroFetchOptions } from 'nitropack'
import type { ZodIssue } from 'zod'
import type {
  PathMethodParams,
  SuccessResponseBody,
  ValidMethods,
} from '../types/api'

import { toValue } from 'vue'

export type { SuccessResponseBody } from '../types/api'

interface ApiOptions {
  // optional flag to disable immediate execution, defaults to false
  lazy?: boolean

  // optional flag to set initial loading state, defaults to false
  // see: https://github.com/forged-com/forgd/pull/2594#discussion_r1894097024
  loading?: boolean

  // optional flag to enable error toast, defaults to false
  toast?: boolean
}

class ApiError extends Error {
  status?: number
  title?: string
  output?: any
  data?: any

  constructor(message?: string, title?: string, output?: any, status?: number, data?: any) {
    super(message)
    this.status = status
    this.title = title
    this.output = output
    this.data = data
  }
}

export function useQuery<P extends keyof paths, M extends ValidMethods<P>>(
  path: P,
  config?: {
    method?: M extends ValidMethods<P> ? M : never
    params?: MaybeRefOrGetter<PathMethodParams<P, M>>
    options?: MaybeRefOrGetter<ApiOptions>
  },
) {
  const logger = useDevLogger('useQuery')

  const runtimeConfig = useRuntimeConfig()
  const baseUrl = import.meta.server ? runtimeConfig.public.apiUrl : '/proxy'

  // cookie
  const cookie = useCookie<string>(runtimeConfig.public.cookie)
  const headers: Record<string, string> = import.meta.server
    ? { cookie: `${runtimeConfig.public.cookie}=${cookie.value}` }
    : {}

  function _parseOptions(options?: MaybeRefOrGetter<ApiOptions>) {
    const newOptions = toValue(options)
    return {
      lazy: newOptions?.lazy ?? false,
      loading: newOptions?.loading ?? false,
      toast: newOptions?.toast ?? false,
    }
  }

  // initial options
  const options = _parseOptions(config?.options)

  // initial params
  const params = ref<PathMethodParams<P, M> | null>(config?.params ? _parseParams(config.params) : null)

  // initial async state
  const status = ref<AsyncDataRequestStatus>(options.loading ? 'pending' : 'idle')
  const loading = ref(options.loading)

  const data = ref<SuccessResponseBody<P, M> | null>(null)
  const error = ref<ApiError | null>(null)

  const clientNext = $fetch.create({
    baseURL: baseUrl,
    headers,
    credentials: 'include',
    onRequest({ options }) {
      logger.debug('onRequest', path, { options })
      // This ensures arrays in query params are serialized in a way that is compatible with the backend
      if (options?.query) {
        for (const key in options.query) {
          if (Array.isArray(options.query[key])) {
            for (const [index, value] of options.query[key].entries()) {
              options.query[`${key}[${index}]`] = value
            }
            delete options.query[key]
          }
        }
        logger.debug('onRequest:query', { query: options.query })
      }
    },
    onResponse({ response }) {
      logger.debug('onResponse', path, { response })
      if (response.status < 300) {
        data.value = response._data
        status.value = 'success'
        error.value = null
      }
      else {
        // other statuses
        status.value = 'idle'
      }
      loading.value = false
    },
    onRequestError({ error }) {
      logger.error('onRequestError', path, { error })
    },
    async onResponseError({ response }) {
      logger.error('onResponseError', path, { response })

      const nuxtApp = useNuxtApp()
      const toast = useAppToast()

      // Create error object to propagate
      const errorData = response._data as object
      const { title, description, output } = parseError(errorData, response.status)
      const enhancedError = new ApiError(description, title, output, response.status, errorData)

      // unauthorised - pop teammate access wall
      if (response.status === 403) {
        await nuxtApp.hooks.callHook('forgd:fetch:unauthorized', { args: config?.params, res: response._data })
      }
      // anything else
      else {
        // developer
        // eslint-disable-next-line no-console
        console.log(`\n${title}\n\n  > ${path}`, output)

        // user
        if (options.toast) {
          const fn = response?.status < 400 ? toast.warning : toast.error
          fn({ title, description })
        }
      }

      // Propagate error to be caught by .catch()
      throw enhancedError
    },
  })

  function deepUnref<T>(obj: T): T {
    const value = toValue(obj)

    if (!value || typeof value !== 'object') {
      return value as any
    }

    if (Array.isArray(value)) {
      return value.map(deepUnref) as any
    }

    const result: Record<string, any> = {}
    for (const key in value) {
      // Skip Vue's internal ref properties
      if (key.startsWith('__v_') || key === '_rawValue' || key === '_value') {
        continue
      }
      result[key] = deepUnref(value[key])
    }
    return result as T
  }

  function _parseParams(params: MaybeRefOrGetter<PathMethodParams<P, M>>) {
    return deepUnref(toValue(params))
  }

  function _error(err?: ApiError) {
    status.value = 'error'
    loading.value = false
    data.value = null
    error.value = err ?? null
  }

  // Execute request immediately
  const execute = async () => {
    status.value = 'pending'
    loading.value = true
    error.value = null

    const params = config?.params ? _parseParams(config.params) : null

    logger.debug('execute', path, { params })

    // Interpolate path parameters if they exist
    let interpolatedPath = path as string
    if (params?.path) {
      for (const [key, value] of Object.entries(params.path)) {
        if (typeof value !== 'string' || value.length === 0) {
          logger.error('Path parameter is undefined', path, { params })
          const err = new ApiError('Path parameter is undefined', 'Invalid Path Parameter', params.path, 400)
          _error(err)
          return
        }
        interpolatedPath = interpolatedPath.replace(`{${key}}`, encodeURIComponent(value as string))
      }
    }

    await clientNext(interpolatedPath, {
      method: config?.method,
      ...params,
    } as NitroFetchOptions<keyof paths, ValidMethods<P>>)
      .catch((err: ApiError) => {
        _error(err)
        throw err // Re-throw to propagate to Promise chain
      })
  }

  // this pattern is borrowed from the Vue docs
  // https://vuejs.org/guide/reusability/composables.html#accepting-reactive-state
  watchEffect(() => {
    logger.debug('watchEffect', path, { config })
    const newParams = config?.params ? _parseParams(config.params) : null
    const newOptions = _parseOptions(config?.options)
    if (newParams !== params.value && !newOptions.lazy) {
      logger.debug('watchEffect:newParams', path, { newParams })
      execute()
    }
    else if (!newOptions.lazy) {
      execute()
    }
  })

  const refresh = () => {
    logger.debug('refresh', path, { path })
    return execute()
  }

  const clear = () => {
    status.value = 'idle'
    data.value = null
    error.value = null
  }

  const result = {
    status,
    loading,
    data,
    error,
    refresh,
    clear,
    execute,
  }

  // Make the result awaitable
  return Object.assign(Promise.resolve().then(() => {
    return new Promise<typeof result>((resolve) => {
      if (options.lazy) {
        resolve(result)
      }
      else {
        watch(status, (newStatus) => {
          if (newStatus === 'success' || newStatus === 'error') {
            resolve(result)
          }
        }, { immediate: true })
      }
    })
  }), result)
}

function parseError(error: any, status: number) {
  // defaults
  const title = errors[status as keyof typeof errors] || 'Error'
  let description = error?.message
  let output: any = error

  // zod errors
  if (error.paramsResult) {
    const { desc, text } = parseZodError(error.paramsResult)
    description = `Invalid parameters (${desc})`
    output = text
  }
  else if (error.queryResult) {
    const { desc, text } = parseZodError(error.queryResult)
    description = `Invalid query (${desc})`
    output = text
  }

  // return
  return {
    title,
    description,
    output,
  }
}

function parseZodError({ issues }: { issues: ZodIssue[] }) {
  const desc = plural(issues, 'issue')
  const text = issues.map((issue: ZodIssue) => {
    return `  - ${issue.path[0]} : ${issue.message}`
  }).join('\n')
  return { desc, text: `\n\n${text}\n\n` }
}

const errors = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  509: 'Bandwidth Limit Exceeded',
  510: 'Not Extended',
}
