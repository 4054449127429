const LiquidityLaunchDesignerPaths = [
  '/liquidity-launch-designer',
  '/liquidity-launch-designer/dashboard',
  '/liquidity-launch-designer/confirmation',
]

export default defineNuxtPlugin({
  name: 'liquidity-launch-designer',
  async setup() {
    const router = useRouter()

    router.beforeEach(async (to) => {
      const isLiquidityLaunchDesignerPath = LiquidityLaunchDesignerPaths.some(path => to.path.startsWith(path))
      if (!isLiquidityLaunchDesignerPath) {
        return
      }

      const isFeatureEnabled = await useFeatureFlag('liquidity-launch-designer')
      if (!isFeatureEnabled) {
        return navigateTo('/')
      }
    })
  },
})
