import payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import unhead_anEO7nxNEFt0YmEXyZAK1lSHvbXOAgxX0kH98KN4CXA from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ocTghzKifIrxyed1gFfohXAIT8Of3MQ2SEzKvWPmFJQ from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_qaX77Ocjx57NKuqomq_AjUbBoZ5_0A26SLhDhmxmjBs from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_4PqW_znenM_Xo_THW3TmCHSsoOBUxfEn4G4Et_9GS_I from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UHnCRr7Xnf6n1g9_TQPPW_9rTcA4zV95Zp7oxD7U1Hg from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Vb7i_lb21it9iEE_36wiMivVEHcJ72W9ETtr0i24mdo from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_yHNoaUKorZCqZJkgFcs5zJ9FrJ533JyJ27_8WhW8ImE from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import preview_client__zsOuMUIJmdpO8_P_drZP3z_7R5SLduB19tcrbAgLyI from "/opt/render/project/src/node_modules/.pnpm/@nuxt+content@3.3.0_drizzle-orm@0.41.0_@opentelemetry+api@1.9.0_@types+pg@8.11.11_bette_7406d675b562fbf4ae7247c977ca75d6/node_modules/@nuxt/content/dist/runtime/plugins/preview.client.js";
import plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8 from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/render/project/src/apps/ui/.nuxt/components.plugin.mjs";
import prefetch_client_h_Lx9DIEP_MLhGSAVpolxI3kSeLiYY2eCemiD9RpdlM from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ from "/opt/render/project/src/apps/ui/.nuxt/floating-vue.mjs";
import plugin_E84_U_7WTs0xfsAFd5zWaz6rDbejKjZF_yTb2b86WtU from "/opt/render/project/src/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescr_0207495bb835a6f5ae7860b54eb3353f/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import slideovers_bXc5MGAYnfwFyzcwRPu_qT3VCosw1mh25cnkbxZCANw from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.21.1_axios@1.8.4_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_ts-node@1_50b60e9064598c91195441de349fb1b0/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_hXLF1AOzj55F_ezoXNuSwtdG_TktDrzzellrKnyQSHY from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.21.1_axios@1.8.4_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_ts-node@1_50b60e9064598c91195441de349fb1b0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_mhoMou0H3zE_VrEVpvPrQaEz_aty6yAK0bOy98ZfIqQ from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.21.1_axios@1.8.4_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_ts-node@1_50b60e9064598c91195441de349fb1b0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_NkIVnbY_9ZWfw_YTOeoyL3KXlFEMXGuorLdIBAVo9R0 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_wDDrD98dViOE2K_XJJAqzPrMOin5CWGT7gnwRIYnYEs from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.2_@types+node@22.13.17_jiti@2.4.2_sass-embedd_418e45dcfa46dffd4741908dcfe30743/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import search_0Q2LOe8L9kX70lt_3qHwE96Gg6im6Oz4g2elGA7HUVI from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/plugins/search.ts";
import lottie_JlHgJmBgVrk_woWC2ljZ7Ngn4EoIVooxVwkhr8Q4FX4 from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/lottie.ts";
import sentry_client_P5Z2aPrkhVqNvV7T62uBkjbkXyHUFrshOzuQ71I5WB8 from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/sentry.client.ts";
import tracing_client_XILydOuQZJaMEXUmabh24Fdm4JFX7te2gS6gpz98Di8 from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/tracing.client.ts";
import access_client_JCwBLRDGeeNRtY2Pdw_cxIJdhDTT6m5bM5OBnc71DYM from "/opt/render/project/src/apps/ui/plugins/access.client.ts";
import liquidity_launch_designer_client_ZzCb2CPIYrdDxrKddIMdMr4ao7sK0jgDWIZOKjbC5Po from "/opt/render/project/src/apps/ui/plugins/liquidity-launch-designer.client.ts";
import market_maker_monitoring_next_client__IMFY_WJSBH4DYgsuUOI5gmuiiwzfgHdYgAwDbKY1a8 from "/opt/render/project/src/apps/ui/plugins/market-maker-monitoring-next.client.ts";
import token_designer_v2_client_mbXK7S5lBsRExyOrg0kg5n0sNfDMbyH2dMxxBpp_pr4 from "/opt/render/project/src/apps/ui/plugins/token-designer-v2.client.ts";
export default [
  payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA,
  unhead_anEO7nxNEFt0YmEXyZAK1lSHvbXOAgxX0kH98KN4CXA,
  router_ocTghzKifIrxyed1gFfohXAIT8Of3MQ2SEzKvWPmFJQ,
  supabase_client_qaX77Ocjx57NKuqomq_AjUbBoZ5_0A26SLhDhmxmjBs,
  payload_client_4PqW_znenM_Xo_THW3TmCHSsoOBUxfEn4G4Et_9GS_I,
  navigation_repaint_client_UHnCRr7Xnf6n1g9_TQPPW_9rTcA4zV95Zp7oxD7U1Hg,
  check_outdated_build_client_Vb7i_lb21it9iEE_36wiMivVEHcJ72W9ETtr0i24mdo,
  chunk_reload_client_yHNoaUKorZCqZJkgFcs5zJ9FrJ533JyJ27_8WhW8ImE,
  preview_client__zsOuMUIJmdpO8_P_drZP3z_7R5SLduB19tcrbAgLyI,
  plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_h_Lx9DIEP_MLhGSAVpolxI3kSeLiYY2eCemiD9RpdlM,
  floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ,
  plugin_E84_U_7WTs0xfsAFd5zWaz6rDbejKjZF_yTb2b86WtU,
  slideovers_bXc5MGAYnfwFyzcwRPu_qT3VCosw1mh25cnkbxZCANw,
  modals_hXLF1AOzj55F_ezoXNuSwtdG_TktDrzzellrKnyQSHY,
  colors_mhoMou0H3zE_VrEVpvPrQaEz_aty6yAK0bOy98ZfIqQ,
  plugin_client_NkIVnbY_9ZWfw_YTOeoyL3KXlFEMXGuorLdIBAVo9R0,
  plugin_wDDrD98dViOE2K_XJJAqzPrMOin5CWGT7gnwRIYnYEs,
  search_0Q2LOe8L9kX70lt_3qHwE96Gg6im6Oz4g2elGA7HUVI,
  lottie_JlHgJmBgVrk_woWC2ljZ7Ngn4EoIVooxVwkhr8Q4FX4,
  sentry_client_P5Z2aPrkhVqNvV7T62uBkjbkXyHUFrshOzuQ71I5WB8,
  tracing_client_XILydOuQZJaMEXUmabh24Fdm4JFX7te2gS6gpz98Di8,
  access_client_JCwBLRDGeeNRtY2Pdw_cxIJdhDTT6m5bM5OBnc71DYM,
  liquidity_launch_designer_client_ZzCb2CPIYrdDxrKddIMdMr4ao7sK0jgDWIZOKjbC5Po,
  market_maker_monitoring_next_client__IMFY_WJSBH4DYgsuUOI5gmuiiwzfgHdYgAwDbKY1a8,
  token_designer_v2_client_mbXK7S5lBsRExyOrg0kg5n0sNfDMbyH2dMxxBpp_pr4
]