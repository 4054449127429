import type { LayoutKey } from '#build/types/layouts'
import type { FeatureFlag } from '@forgd/shared/feature-flags'
import type { Router } from 'vue-router'
import { useFeatureFlag } from './useFeatureFlag'

interface NextFeatureConfig {
  /**
   * The feature flag to check.
   */
  featureFlag: FeatureFlag
  /**
   * The paths to swap to the next feature.
   */
  paths: string[]
}

const NextPrefix = '_next'

function swapPaths(router: Router, config: NextFeatureConfig, isEnabled: boolean) {
  const routes = router.getRoutes()
  router.clearRoutes()

  routes.forEach((route) => {
    const isV1Path = config.paths.some(path => route.path.startsWith(`/${path}`))
    const isV2Path = config.paths.some(path => route.path.startsWith(`/${NextPrefix}/${path}`))

    if (isEnabled) {
      if (isV1Path) {
        return
      }
      if (isV2Path) {
        route.path = route.path.replace(`/${NextPrefix}/`, '/')
        if (route.meta.layout) {
          route.meta.layout = `next-${route.meta.layout}` as LayoutKey
        }
      }
    }
    else if (isV2Path) {
      return
    }
    router.addRoute(route)
  })
}

/**
 * Creates a plugin that swaps a whole layer to the `next` version. If the given feature flag is on
 * the plugin will swap the paths of the given routes to the `next` version.
 *
 * @param config - The configuration for the feature flag.
 * @returns A plugin that swaps the paths of a feature flag.
 */
export function createNextFeaturePlugin(config: NextFeatureConfig) {
  return defineNuxtPlugin({
    async setup() {
      const router = useRouter()
      const isFeatureEnabled = await useFeatureFlag(config.featureFlag)

      if (config.paths.some(p => router.currentRoute.value.path.startsWith(`/${p}`))) {
        swapPaths(router, config, isFeatureEnabled)
        return
      }

      swapPaths(router, config, isFeatureEnabled)
    },
  })
}
