import type { AcademyNavGroup } from '../utils/nav'
import { defineNuxtPlugin } from '#app'
import { fetchAcademyNav } from '../utils/nav'

export default defineNuxtPlugin((nuxtApp) => {
  const searchData = ref<AcademyNavGroup[]>([])
  const academyNav = fetchAcademyNav()
  // network is free, preload search
  onNuxtReady(() => {
    queryCollectionSearchSections('pages').then((res) => {
      searchData.value = res.map(val => ({
        ...val,
        path: val.id,
      }))
    })
  })
  const searchGroup = computed(() => {
    return academyNav.data.value?.map((item) => {
      return {
        key: item.path,
        icon: item.icon,
        label: `Forgd Academy > ${item.title}`,
        to: item.path,
        commands: [...item.children].map(c => ({
          icon: item.icon,
          label: c.title,
          // @ts-expect-error untyped
          to: c.children?.[0].path || c.path,
        })),
      }
    }) || []
  })
  const filterGroup = computed(() => {
    return searchGroup.value.map((group) => {
      group.commands = searchData.value.filter((i) => {
        // @ts-expect-error untyped
        return i.path.startsWith(group.to)
      }).map(i => ({
        ...i,
        to: i.path,
        icon: group.icon,
        label: `${group.label.split('>')[1].trim()} > ${i.title}`,
      }))
      return group
    })
  })
  nuxtApp.hooks.hook('forgd:search', async (ctx) => {
    // @ts-expect-error untyped
    ctx.nav.push(searchGroup)
    ctx.filter.push(filterGroup)
  })
})
