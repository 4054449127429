import { defineThemeConfig } from './utils/theme'

export default defineAppConfig({
  /**
   * Cmd+Click props to go to original component theme file
   * @see @nuxt/ui/dist/runtime/ui.config
   */
  ui: defineThemeConfig({
    primary: 'brand',

    popover: {
      width: '',
      popper: {
        strategy: 'absolute',
      },
      default: {
        openDelay: 300,
        closeDelay: 0,
      },
    },

    checkbox: {
      color: 'text-forgd-green-400',
    },

    button: {
      font: 'font-display font-semibold',
      variant: {
        tooltip: 'text-forgd-primary-300',
      },
      color: {
        black: {
          solid: 'bg-forgd-primary-900 text-forgd-bgd-100',
        },
      },
    },

    toggle: {
      color: 'forgd-primary-300',
    },

    notifications: {
      position: '-top-2 bottom-auto left-10 z-[200]',
    },

    dropdown: {},

    card: {
      rounded: 'rounded-lg',
      ring: 'ring-forgd-bgd-600',
      shadow: '',
      background: 'bg-forgd-bgd-100',
      divide: 'divide-forgd-bgd-600',
      header: {
        base: 'h-[70px] flex items-center',
      },
      body: {
        padding: 'px-0 py-0 sm:p-0',
      },
    },

    divider: {
      border: {
        base: 'border-forgd-bgd-600',
      },
    },

    textarea: {
      color: {
        white: {
          outline: 'shadow-none focus:ring-forgd-primary-300',
        },
      },
      variant: {
        outline: 'shadow-none focus:ring-forgd-primary-300',
      },
    },

    // Nuxt UI Pro
    // - This is legacy copied from Nuxt UI Pro to avoid breaking changes
    // - Safe to remove when we upgrade Nuxt UI to v3
    variables: {
      light: {
        background: '255 255 255',
        foreground: 'var(--color-gray-700)',
      },
      dark: {
        background: 'var(--color-gray-900)',
        foreground: 'var(--color-gray-200)',
      },
      header: {
        height: '4rem',
      },
    },
    icons: {
      dark: 'i-heroicons-moon-20-solid',
      light: 'i-heroicons-sun-20-solid',
      system: 'i-heroicons-computer-desktop-20-solid',
      search: 'i-heroicons-magnifying-glass-20-solid',
      external: 'i-heroicons-arrow-up-right-20-solid',
      chevron: 'i-heroicons-chevron-down-20-solid',
      hash: 'i-heroicons-hashtag-20-solid',
      menu: 'i-heroicons-bars-3-20-solid',
      close: 'i-heroicons-x-mark-20-solid',
      check: 'i-heroicons-check-circle-20-solid',
    },
    presets: {
      button: {
        primary: {
          color: 'white',
          variant: 'solid',
        },
        secondary: {
          color: 'gray',
          variant: 'ghost',
        },
        input: {
          color: 'white',
          variant: 'outline',
          ui: {
            font: '',
            color: {
              white: {
                outline: 'ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:ring-gray-300 dark:hover:ring-gray-700 hover:bg-gray-100/50 dark:hover:bg-gray-800/50 text-gray-400 dark:text-gray-500 hover:text-gray-700 dark:hover:text-gray-200 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
              },
            },
          },
        },
      },
    },
  }),
})
